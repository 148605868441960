.experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px;
  gap: 10px;
  width: 100vw;
  position: relative;
  background-color: var(--thirdColor);
  border-top: 1px solid black;
}

.experience-company-zone {
  margin-top: 40px;
  width: 70vw;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: column;
  gap: 15px;
  justify-content: center;
  padding: 40px;
}

.experience-company-box {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  position: relative;
  border-radius: 10px;
  z-index: 1;
}

.experience-company-box-image {
  position: absolute;
  width: 80%;
  height: 80%;
  object-fit: contain;
}

@media only screen and (max-width: 1023px) {
  .experience-container {
    padding: 30px 0;
    min-height: 40vh;
  }

  .experience-grid-container {
    display: flex;
    align-items: center;
    height: 230px;
  }

  .experience-company-zone {
    width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    margin-top: 15px;
    overflow: hidden;
    justify-content: flex-start;
    position: relative;
  }

  .experience-company-box {
    display: none;
    width: 50vw;
    margin-left: 15vw;

    transition: all 400ms ease;
  }

  .experience-company-box.active {
    display: flex;
  }

  .experience-company-box.next,
  .experience-company-box.nextnext,
  .experience-company-box.prev,
  .experience-company-box.prevprev {
    display: flex;
    position: absolute;
  }

  .experience-company-box.next {
    transform: translateX(300%);
  }

  .experience-company-box.nextnext {
    transform: translateX(400%);
  }

  .experience-company-box.prev {
    transform: translateX(-300%);
  }

  .experience-company-box.prevprev {
    transform: translateX(-400%);
  }

  .experience-company-box-image {
    width: 90%;
    height: 90%;
  }
}
